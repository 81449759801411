<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div class="mt-1" v-if="!isLoading">
      <b-calendar
        v-model="inputVal"
        :date-info-fn="dateClass"
        locale="it"
        @context="onContext"
        @selected="onSelected"
        :hide-header="true"
        start-weekday="1"
        v-bind="labels['it'] || {}"
        :max="max"
      ></b-calendar>
      <!-- :initial-date="initialDate" -->
    </div>
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import moment from "moment";
moment.locale("it");
import { toLastDay } from "@/utils/dates";
import BaseIcon from "@/components/BaseIcon";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [ConfirmModalMixin],
  data() {
    return {
      isLoading: false,
      repository: "consolidated_treasury",
      calMonth: null,
      calYear: null,
      actualMonth: null,
      actualYear: null,
      // initialDate: null,
      consolidatedDates: null,
      calDate: null,
      labels: {
        it: {
          labelPrevDecade: "Decade Precedente",
          labelPrevYear: "Anno Precedente",
          labelPrevMonth: "Mese Precedente",
          labelCurrentMonth: "Mese Corrente",
          labelNextMonth: "Mese Successivo",
          labelNextYear: "Anno Successivo",
          labelNextDecade: "Decade Successiva",
          labelToday: "Oggi",
          labelSelected: "",
          labelNoDateSelected: "Nessuna Data Selezionata",
          labelCalendar: "Calendario",
          labelNav: "Navigazione",
          labelHelp: "Usare le frecce per navigare nel calendario",
        },
      },
    };
  },
  props: {
    value: null,
    max: null,
  },
  methods: {
    toLastDay,
    initDefaultFormValues() {
      this.calMonth = moment().format("MM");
      this.calYear = moment().format("YYYY");
      this.actualMonth = moment().format("MM");
      this.actualYear = moment().format("YYYY");
      this.consolidatedDates = [];
    },
    onContext(ctx) {
      // {
      //   "selectedYMD": "",
      //   "selectedDate": null,
      //   "selectedFormatted": "No date selected",
      //   "activeYMD": "2022-03-28",
      //   "activeDate": "2022-03-27T22:00:00.000Z",
      //   "activeFormatted": "Monday, March 28, 2022",
      //   "disabled": false,
      //   "locale": "en-US",
      //   "calendarLocale": "en-US",
      //   "rtl": false
      // }

      this.calMonth = moment(ctx.activeYMD, "YYYY-MM-DD").format("MM");
      this.calYear = moment(ctx.activeYMD, "YYYY-MM-DD").format("YYYY");
      let changed =
        this.calMonth !== this.actualMonth || this.calYear !== this.actualYear;
      // console.debug("Mese cambiato? ", changed ? "S" : "N");
      if (changed) {
        this.inputVal = ctx.activeYMD;
        this.actualMonth = this.calMonth;
        this.actualYear = this.calYear;
        this.fetchConsolidatedDates();
      }
    },
    // onSelected(ymd /*, date*/) {
    //   this.$showSnackbar({ preset: "info", text: `Removing ${ymd}...` });
    // },
    onSelected(ymd /*, date*/) {
      let isDeletable = true;
      // controllo se è una data per cui si possa eliminare il consolidamento creato
      if (
        document.querySelector(`[data-date="${ymd}"]`) &&
        document
          .querySelector(`[data-date="${ymd}"]`)
          .classList.contains("consolidated-date--previous")
      )
        isDeletable = false;

      // se è una data consolidata chiedo di eliminare...
      if (this.consolidatedDates.map((d) => d.consolidated_at).includes(ymd)) {
        this.showConfirm({
          yesCallback: () => {
            this.isLoading = true;
            // save
            if (isDeletable) {
              this.removeConsolidatedDate(ymd);
            } else {
              this.$showSnackbar({
                preset: "error",
                text: "Non puoi deconsolidare la giornata selezionata in quanto non è l'ultima consolidata.",
              });
              this.isLoading = false;
            }
          },
          // noCallback: null,
          noCallback: () => {
            this.viewConsolidatedDate(ymd);
          },
          title: "Conferma operazione",
          message: `Quale operazione desideri effettuare per il Foglio Quadratura del giorno ${moment(
            ymd
          ).format("DD/MM/YYYY")}?`,
          yesLabel: "Elimina consolidatura",
          // noLabel: "Annulla",
          noLabel: "Visualizza",
          okDisabled: !isDeletable,
          // yesVariant:
          // noVariant:
          // headerBgVariant:
        });
      } else {
        // ... carico il foglio quadratura
        this.viewConsolidatedDate(ymd);
      }
    },
    dateClass(ymd, date) {
      const day = moment(date).format("YYYY-MM-DD");
      // const currentMonth = this.calDate.getMonth();
      const currentMonth = moment(this.calDate, "YYYY-MM-DD").month();
      const dayMonth = moment(date).month();
      if (this.calDate && currentMonth !== dayMonth) {
        return "calendar custom-d-none"; // Hide days from other months
      }
      const isBeforeAnotherDate = this.max
        ? moment(day).isBefore(moment(this.max).format("YYYY-MM-DD"))
        : null;
      // return this.consolidatedDates.map((d) => d.consolidated_at).includes(day)
      //   ? "calendar consolidated-date"
      //   : "calendar free-date";
      if (this.consolidatedDates.map((d) => d.consolidated_at).includes(day)) {
        console.log("progressive", this.isProgressive);
        if (!this.isProgressive) return "calendar consolidated-date--last";
        return `calendar ${
          isBeforeAnotherDate
            ? "consolidated-date--previous"
            : "consolidated-date--last"
        }`;
      } else {
        return "calendar free-date";
      }
    },
    fetchConsolidatedDates() {
      this.isLoading = true;
      let Repo = RepositoryFactory.get("consolidated_treasury");
      let from = `${this.calYear}-${this.calMonth}-01`;
      let to = this.toLastDay(`${this.calYear}-${this.calMonth}-01`);
      let queryString = `byDateConsolidated=${from},${to}`;
      return Repo.index(queryString)
        .then((response) => {
          this.consolidatedDates = response.data.data;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    viewConsolidatedDate(ymd) {
      this.$emit("view", ymd);
    },
    removeConsolidatedDate(ymd) {
      const Repo = RepositoryFactory.get(this.repository);
      let payload = {
        consolidated_treasury: [ymd],
        field: "consolidated_at",
      };
      Repo.bulk_destroy(payload)
        .then((response) => {
          let errMsg = `Righe di consolidature per la giornata del ${moment(
            ymd
          ).format("DD/MM/YYYY")} rimosse`;
          if (response.status === 206) {
            errMsg = this.$getErrorMessage(response);
          }
          this.fetchConsolidatedDates();
          this.$emit("reload");
          this.$showSnackbar({
            preset: "success",
            text: `${errMsg}`,
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          debugger;
          this.isLoading = false;
        });
    },
    ...mapGetters("auth", {
      getSetting: "setting",
    }),
  },
  beforeMount() {
    this.initDefaultFormValues();
  },
  mounted() {
    this.fetchConsolidatedDates();
    // DEBUG simulate data loaded
    // this.consolidatedDates = ["2022-03-14", "2022-03-22"];
  },
  components: {
    BaseIcon,
  },
  computed: {
    isProgressive() {
      return this.getSetting()("attribute_ACCT_value") === "Y" ? true : false;
    },
    inputVal: {
      get() {
        return this.calDate;
      },
      set(val) {
        this.calDate = val;
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped lang="scss">
// :deep(.consolidated-date) {
//   background-color: red;
// }
// :deep(.free-date) {
//   background-color: green;
// }
:deep(.calendar) {
  margin: 2px;
  // border: solid 1px black;
  &.consolidated-date--previous {
    background-color: rgb(111, 225, 225);
  }
  &.consolidated-date--last {
    background-color: rgb(153, 250, 153);
  }
  &.free-date {
    background-color: rgb(240, 78, 78);
  }
  &.custom-d-none {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    touch-action: none;
    cursor: default;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  &:not(.custom-d-none) + .calendar.custom-d-none ~ * {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    touch-action: none;
    cursor: default;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
}
</style>
